import React, {useState,useEffect, useMemo, useCallback, memo} from 'react';
import './App.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

function getFormattedDate(date) {
  if(date == null){
    return "";
  }
  var year = date.getFullYear();

  var month = (1+date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}


function reviveDate(strDate){
  if(strDate == null)
    return null;

  let dateObj = new Date(strDate);
  if (isNaN(dateObj.getTime()))
  {
    dateObj = null;
  }
  return dateObj;
}

async function statusCheck(res) {
  if (!res.ok) {
    throw new Error(await res.text());
  }
  return res;
}

function NavBar(props){
  console.log("NavBar rendering:");

  const handleRefresh = () => {
    props.refresh()
  }

  const handleOverdue = () => {
    props.overdue()
  }

  return(
    <nav className="mt-3 navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <div className="navbar-header">
              <a className="navbar-brand" href="#">Todo List</a>
            </div>
            <ul className="nav navbar-nav justify-content-end">
              <li id ="refresh" className="nav-item"><a className="nav-link" href="#" onClick={handleRefresh}><i className="bi bi-arrow-repeat"></i> Refresh</a></li>
              <li id ="overdue" className="nav-item"><a className="nav-link" href="#" onClick={handleOverdue}><i className="bi bi-fire"></i> Overdue</a></li>
             </ul>
          </div>
        </nav>
  );
}


function UpdateTask(props){
  const [title, setTitle] = useState(props.title)
  const [dueDate, setDueDate] = useState(new Date(props.dueDate));

  const submitUpdateTask = async () => {
    try {
      const response = await fetch('https://todo-list.fly.dev/updatetask', {
        method: 'POST', 
        headers: {
          'Accept' : 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
          data: {
            title: title,
            dueDate: dueDate
          },
          _id : props._id
        })
      });
      const data = await response.json();
      console.log(data)
      if (data.status === "OK"){
        props.hideUpdate()
        props.updateTask(data.data, props._id)
      }
    } catch (error) {
      console.log(error)
      console.error(error.responseText)
    }
  }

  return (
    <tr>
      <td className="text-center" style={{verticalAlign: "middle"}}>
        Update Task
      </td>
      <td className="text-center">
        <input type="text" className = "form-control" onChange={(event) => setTitle(event.target.value)} placeholder="Type your task here. (Required)" value={title}/>
      </td>
      <td className="text-center" >
        <DatePicker className ="form-control" placeholder="Due Date(mm/dd/yyyy)" selected={dueDate} onChange={(date) => setDueDate(date)} value={getFormattedDate(new Date(dueDate))}/>
      </td>
        <td className="text-center">
      </td>
      <td className="text-end">
        <button type="button" className="btn btn-default btn-warning" alt="Update Task" onClick={submitUpdateTask}>
          <i className="bi bi-pencil-square"></i> Update
        </button>
      </td>
    </tr>
  );
}


const TodoItem =  function(props){
  const [checked, setChecked] = useState(props.completed)
  const [completeDate, setCompleteDate] = useState(props.completeDate)
  const late = Date.now() > new Date(props.dueDate)
  const [updateStatus, setUpdateStatus] = useState(false)

  useEffect(() => {
    if (checked) setCompleteDate(null)
    else setCompleteDate(new Date(Date.now()))
  }, [checked]);

  const toggleCheck = async () => {
    try {
      const response = await fetch('https://todo-list.fly.dev/updatetask', {
        method: 'POST', 
        headers: {
          'Accept' : 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
          data: {
            completed: !checked,
            completeDate: completeDate
          },
          _id : props._id
        })
      });
      const data = await response.json();
      console.log(data)
      if (data.status === "OK"){
        setChecked(data.data.completed)    
        props.updateTask(data.data, props._id)
      }
    } catch (error) {
      console.log(error)
      console.error(error.responseText)
    }
  }
  
  const handleDeleteTask = async () => {
    if (window.confirm("Are you sure?")){ 
      try {
        const response = await fetch('https://todo-list.fly.dev/deletetask', {
          method: 'POST', 
          headers: {
            'Accept' : 'application/json',
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify({
            _id: props._id
          })
        });
        const data = await response.json();
        console.log(data)
        if (data.status === "OK"){
          props.deleteTask(props._id)
        }
      } catch (error) {
        console.log(error)
        console.error(error.responseText)
      }
    }
  }

  const handleUpdateTask = () => {
		setUpdateStatus(true)
	}

  if (updateStatus) 
    return <UpdateTask
      _id={props._id} 
      title={props.title} 
      dueDate={props.dueDate} 
      updateTask={props.updateTask}
      hideUpdate={() => {setUpdateStatus(false)}}
    />
  else return(
    <tr className={props.completed ? "table-success": props.dueDate==null ? "": late ? "table-danger" : null}>
      <td className="text-center"><input type="checkbox" className="form-check-input" checked={props.completed} onChange={toggleCheck}/></td>
      <td className="text-left" onDoubleClick={handleUpdateTask} style={{textDecoration:props.completed?'line-through':'none'}}>{props.title}</td>
      <td className="text-center" onDoubleClick={handleUpdateTask}>{props.dueDate===null?"":getFormattedDate(new Date(props.dueDate))}</td>
      <td className="text-center">{props.completeDate===null?checked?"":"":getFormattedDate(new Date(props.completeDate))}</td>
      <td className="text-center">
        <button type="button" className="btn btn-danger btn-xs" onClick={handleDeleteTask} alt="Delete the task">
          <i className="bi bi-trash"></i>
        </button> &nbsp;
        <a target="_blank" href={`mailto:?subject=${props.title}`} >
          <button type="button" className="btn btn-info btn-xs" alt="Send an email">
          <i className="bi bi-envelope"></i>
          </button>
        </a>
      </td>
    </tr>
  );
};


function NewTask(props){
  const [title, setTitle] = useState("")
  const [dueDate, setDueDate] = useState(null);

  const submitNewTask = async () => {
    if (title === ""){
      alert("Task title is require!");
    } else {
      const response = await fetch('https://todo-list.fly.dev/newtask', {
        method: 'POST', 
        headers: {
          'Accept' : 'application/json',
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
          title: title,
          dueDate : dueDate
        })
      })
      const data = await response.json()
      console.log(data)
      if(data.status === "OK"){
        console.log("data.data ", data.data)
        props.newTask(data.data)
        setTitle("")
        setDueDate(null)
        props.handleInputMode()
      }
    }
  }

  return (
    <tr>
      <td className="text-center">
        New Task
      </td>
      <td className="text-center">
        <input value = {title}  type="text" className = "form-control" onChange={(event) => setTitle(event.target.value)} placeholder="Type your task here. (Required)"/>
      </td>
      <td className="text-center" >
        <DatePicker className ="form-control" placeholder="Due Date(mm/dd/yyyy)" selected={dueDate} onChange={(date) => {setDueDate(new Date(date.setHours(23,59,59)))}}/>
      </td>
      <td className="text-end" colSpan = "2"> 
      <button type="button" className="btn btn-default btn-danger" onClick={props.handleInputMode}>
        Cancel
      </button>
        &nbsp; 
      <button type="button" className="btn btn-default btn-success" onClick={submitNewTask} alt="Add New Task">
        Submit
      </button>
      </td>
    </tr>
  );
}

const TodoList = function(props){
  const [inputMode, setInputMode] = useState(false)
  // const [overdueStatus, setOverdueStatus] = useState(true)
  const [tasks, setTasks] = useState([])
  const [overdueTasks, setoverdueTasks] = useState([])

  const toggleInputMode = function(){
    setInputMode(!inputMode)
  }

  const handleNewTask = (newTask) => {
    setTasks([...tasks, newTask])
  }

  const filteredTasks = useMemo(() => {
    if (props.overdue) {
      return overdueTasks;
    }
    return tasks;
  }, [props.overdue, tasks, overdueTasks]);


  const handleUpdateTask = (updatedTask, id) => {
    const newTasks = tasks.map(task => {
      if(task._id === id){
        const newTask = {
          ...task,
          ...updatedTask
        }
        return newTask
      }
      else{
        return task
      }
    })
    setTasks(newTasks);
  }

  const handleDeleteTask = (deletedTaskID) => {
    setTasks(task => task.filter(task => task._id !== deletedTaskID))
  }

  const fetchData = async function(){
    try {
      const response = await fetch("https://todo-list.fly.dev/fetchtasks")
      const data = await response.json()
      if (data.status === "OK") setTasks(data.data)
    }catch (error){
      console.log(error)
      console.error(error.responseText)
    }  
  }
  
  useEffect(() => {
    fetchData()
  }, [props.refresh]);

  useEffect(() => {
    const currentOverdueTasks = tasks.filter((task) => {
      const isLate = Date.now() > new Date(task.dueDate)
      return isLate && !task.completed
    });
    setoverdueTasks(currentOverdueTasks);
  }, [tasks])

  const newTaskButton = (
    <div className = "text-end">
      <button  type="button" className="btn btn-default btn-success" onClick={toggleInputMode} alt="Add New Task">
          <i className="bi bi-plus-square"></i> Add New Task
      </button>
    </div>
  );
  return(
    <main>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Done</th>
            <th className = "text-center">Task</th>
            <th className = "text-center">Due date</th>
            <th className = "text-center">Complete date</th>
            <th className = "text-center">Tools</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {tasks.length < 1?<td colSpan="5" className="text-center"> Hurrah! There is nothing to do, wait really? </td>:null}
          </tr>
          {
            filteredTasks.map(
              function(element){
                return <TodoItem
                  key={element._id} 
                  _id={element._id} 
                  title={element.title} 
                  dueDate={element.dueDate} 
                  completed={element.completed} 
                  completeDate={element.completeDate} 
                  updateTask={handleUpdateTask}
                  deleteTask={handleDeleteTask}
                />
            })
          }
          {inputMode?<NewTask newTask={handleNewTask} handleInputMode={toggleInputMode}/>:null}
        </tbody>
      </table>
      {inputMode?null:newTaskButton}
      </main>
  );
}

function App() {
  const [refresh, setRefresh] = useState(0)
  const [overdue, setOverdue] = useState(false)
  return (
    <div className="App">
      <div className="row">
        <div className = "col-2"></div>
        <div className = "col-8">
          <NavBar 
            refresh={() => {setRefresh(refresh + 1)}} 
            overdue={() => {setOverdue(!overdue)}}
          />
          <TodoList refresh={refresh} overdue={overdue}/>
          <br/><br/><br/>
        </div>
        <div className = "col-2"></div>
      </div>  
    </div>
  );
}

export default App;
